* {
    box-sizing: border-box;

    font-family: "Rubik", sans-serif;
    font-weight: 700;
    font-style: normal;
}

html,
body,
#root,
.page_box {
    width: 100%;
    height: 100%;
}

.regular {
    font-family: "Rubik", serif;
    font-weight: 400;
    font-style: normal;
}

.bold {
    font-family: "Rubik", serif;
    font-weight: 400;
    font-style: normal;
}

::-webkit-scrollbar {
    /* 滚动条整体部分 */
    width: 6px;
    margin-right: 0px;
}

::-webkit-scrollbar:horizontal {
    height: 4px;
    margin-bottom: 4px;
}

::-webkit-scrollbar-thumb {
    /* 滑块 */
    width: 2px;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:hover {
    /* 鼠标移入滑块 */
    background: #909090;
}

@keyframes scaleUpDown {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

.header-box {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    font-family: "Rubik", sans-serif;
    font-weight: normal;
    font-size: 0.14rem;
    color: rgba(#ffffff, 0.7);
    line-height: 0.17rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 1rem;
    @media (max-width: 900px) {
        text-align: center;
        display: block;
        font-size: 0.2rem;
        line-height: 0.24rem;
        padding: 0 0.4rem;
    }
    .link_box {
        @media (max-width: 900px) {
            display: flex;
            justify-content: space-around;
            padding: 0.3rem 0;
        }
    }
    .text {
        color: rgba(#ffffff, 0.7);
        padding: 0 0.2rem;
    }
    .ml {
        border-left: 0.01rem solid rgba(#ffffff, 0.4);
        border-right: 0.01rem solid rgba(#ffffff, 0.4);
    }
}

.page {
    width: 100%;
    height: 100%;
    background: url("../images/bg.png") no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 900px) {
        align-items: start;
        justify-content: start;
    }
    .box {
        margin-left: 20%;
        margin-bottom: 10%;
        position: relative;
        padding-left: 0.56rem;
        @media (max-width: 900px) {
            margin-left: 10%;
            margin-top: 2rem;
        }
        .logo {
            width: 1.72rem;
        }
        .title {
            font-family: "Rubik", sans-serif;
            font-weight: normal;
            font-size: 0.48rem;
            color: #ffffff;
            line-height: 0.57rem;
            margin-top: 0.22rem;
            @media (max-width: 900px) {
                margin-top: 0.36rem;
            }
        }
        .text {
            font-family: "Rubik", sans-serif;
            font-weight: normal;
            font-size: 0.24rem;
            color: rgba(#ffffff, 0.6);
            line-height: 0.28rem;
            margin-top: 0.12rem;
        }
        .google_play {
            width: 2.72rem;
            height: 0.8rem;
            margin-top: 0.32rem;
            img {
                width: 100%;
            }
            @media (max-width: 900px) {
                margin-top: 0.48rem;
                width: 3.4rem;
                height: 1rem;
            }
        }
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 0.08rem;
            height: 100%;
            border-radius: 0.04rem;
            background-color: #d8d8d8;
            opacity: 0.2;
        }
    }
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0.4rem 0 0 1rem;
    width: 100%;
    @media (max-width: 900px) {
        display: none;
    }
    .logo_box {
        display: flex;
        align-items: center;

        .logo {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 0.08rem;

            img {
                width: 100%;
                height: 100%;
                border-radius: 0.08rem;
            }
        }
        .text {
            font-family: "Rubik", sans-serif;
            font-weight: normal;
            font-size: 0.24rem;
            color: #ffffff;
            line-height: 0.28rem;
            margin-left: 0.14rem;
        }
    }
}

.pact-page {
    width: 100%;
    height: 100%;
    background: url("../images/bg.png") no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Rubik", sans-serif;
    padding: 0 0.5%;
    @media (max-width: 900px) {
        padding: 0 0.4rem;
    }
    .pact-box {
        width: 98%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80%;
        @media (max-width: 900px) {
            flex-direction: column;
        }
        .title {
            min-width: 3.8rem;
            color: #fff;
            height: 100%;
            font-weight: normal;
            font-size: 0.48rem;
            color: #ffffff;
            line-height: 0.57rem;
            margin-right: 2%;
            margin-left: 10%;
            @media (max-width: 900px) {
                margin: 0;

                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .border {
                margin-top: 0.15rem;
                width: 100%;
                height: 0.08rem;
                border-radius: 0.04rem;
                opacity: 0.2;
                background: #d8d8d8;
                @media (max-width: 900px) {
                    width: 2rem;
                }
            }
        }
        .box {
            width: 62.5%;
            height: 100%;
            border: 0.02rem solid #ffffff;

            padding: 0.56rem 0.62rem;
            border-radius: 0.4rem;
            position: relative;
            z-index: 2;
            
            &::before {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                backdrop-filter: blur(0.5rem);
                z-index: 0;
                border-radius: 0.4rem;
            }
            @media (max-width: 900px) {
                height: 90%;
                width: 100%;
                padding: 0.4rem;
            }

            .pact-cont {
                position: relative;
                z-index: 2;
                height: 100%;
                overflow: auto;
                scrollbar-width: none; /* Firefox */
                &::-webkit-scrollbar {
                    display: none;
                }
                p,
                li {
                    font-family: "Rubik", sans-serif;
                    padding-bottom: 0.3rem;
                    font-weight: 500;
                    font-size: 0.16rem;
                    line-height: 0.24rem;
                    color: rgba(#ffffff, 0.8);
                    @media (max-width: 900px) {
                        color: #fff;
                        font-size: 0.26rem;
                        line-height: 0.40rem;
                    }
                }

                h2 {
                    font-family: "Rubik", sans-serif;
                    font-size: 0.26rem;
                    line-height: 0.31rem;
                    font-weight: 700;
                    color: #fff;
                    @extend .bold;
                    margin-bottom: 0.14rem;
                    margin-top: 0.26rem;
                    border-radius: 0.12rem;
                    @media (max-width: 900px) {
                        font-size: 0.40rem;
                        line-height: 0.47rem;
                    }
                }

                strong {
                    font-weight: bold;
                }
            }
        }
    }
    .header {
        @media (max-width: 900px) {
            display: flex;
            justify-content: center;
            padding-left: 0;
        }
    }
}
